import { useState, useEffect, useMemo } from 'react';
import useAccess from "../auths_&_requests/requests"
import Streams from './components/Streams'
import FlowDomManagement from './components/Flow'
import "./ledger.css"
import { ScrollSync, ScrollSyncPane } from 'react-scroll-sync';
import CreateFlow from './components/CreateFlow';
import EditFlow from './components/EditFlow';
import LeftSide from '../defualt_Layouts/leftSide';
import RightSide from '../defualt_Layouts/rightSide';
import SubNav from '../defualt_Layouts/subNav';
import SubNav_mobile from '../defualt_Layouts/subNav_mobile';
import useGlobalState from "../../Store_&_State/GlobalState"

import { clear_nav, ledger_nav } from '../defualt_Layouts/slices/navSlice'
import { ledger_mobileNav } from '../defualt_Layouts/slices/navSliceMobile'
import { useSelector, useDispatch } from 'react-redux'
import toast, { Toaster } from 'react-hot-toast';

import NumberFormat from 'react-number-format';
import { MobileProfileSlide } from '../defualt_Layouts/accounts';
import { MobileOptions } from '../defualt_Layouts/options';
// import BusinessProfile from '../defualt_Layouts/BusinessProfile';
import { SelectApp } from '../defualt_Layouts/selectApp';
import { DeleteFlowModal } from './components/deleteFlowModal';
import { getRequest, deleteRequest } from '../auths_&_requests/requests';

function Ledger(stream_length) {
  const [blurIndex, setBlurIndex] = useState(0);
  const [total_balance_dsp, setTotal_balance_dsp] = useState([]);
  
  const {main_Toaster_visibility, setMain_Toaster_visibility} = useGlobalState('');
  const {total_balance, setTotal_balance} = useGlobalState('');
  const {grandTotal, setGrandTotal} = useGlobalState('');
  const {general_currency, setMain_general_currency} = useGlobalState('');
  const {flowId, setFlowId} = useGlobalState([]);
  const {SelectedAccount, setSelectedAccount} = useGlobalState([]);
  const {refreshFlowList, setRefreshFlowList} = useGlobalState([]);

  const streamWidth = 27.5
  const periodWidth = 46
  const table_width = (stream_length*streamWidth) + periodWidth

  const dispatch = useDispatch()

  useEffect(
    () => {
      setTotal_balance_dsp(total_balance)
    },[total_balance]
  )

  useEffect(
    () => {
        dispatch(ledger_nav())
        dispatch(ledger_mobileNav())
    },[]
  )

  const closeModal = () => {
    const elem1 = document.getElementById('deleteFlowModal_Overlay')
    const elem2 = document.getElementById('deleteFlowModal') 
    const elem3 = document.getElementById('mobile_DeleteFlowModal_Overlay')
    const elem4 = document.getElementById('mobile_DeleteFlowModal') 
    elem1.style.height = '0%' 
    elem2.style.height = '0%' 
    elem2.style.marginTop = '0%' 

    elem3.style.height = '0%' 
    elem3.style.backgroundColor = 'rgba(0, 0, 0,0)' 
    elem4.style.height = '0%' 
    elem4.style.marginTop = '0%' 
  }

  const delete_success = (success) => {
    setRefreshFlowList(refreshFlowList+1)
  }
  
  const delete_failed = (failed) => {
  }

  const deleteFlowData = async()=>{
    const doToast= true
    const loadingToast= 'Deleting Cash Flow Data...'
    const successToast= 'Cash Flow Deleted'
    const url = `backend_Ledger/cashflow/${flowId}/delete/`
    deleteRequest(url, SelectedAccount, delete_failed, delete_success, doToast, loadingToast, successToast);
    closeModal()
    // setDisplay("none")
    const EditDeleteOverlay = document.getElementById('EditDeleteOverlay')
    EditDeleteOverlay.style.display = 'none'
  };

  return (
    <>
      <section id="ledger_section" className='column'style={{filter:`blur(${blurIndex}px)`}}>
        <SubNav visibility="visible"/>
        <div className='row overlay'>
          <LeftSide/>
            <ScrollSync>
            <div id="row1_scroll_overlay">
            <Toaster
              position="top-center"
              reverseOrder={false}
              gutter={8}
              containerClassName=""
              containerStyle={{}}
              toastOptions={{
                // Define default options
                className: '',
                duration: 5000,
                style: {
                  background: '#7f77e0',
                  color: '#fff',
                  visibility: main_Toaster_visibility
                },

                // Default options for specific types
                success: {
                  duration: 3000,
                  theme: {
                    primary: 'green',
                    secondary: 'black',
                  },
                },
              }}
            />
              <span id='row1' >
                <div id="big_table_overlay"> 
                  <div id="table_overlay" className="table_overlay" >
                    <div>
                      <div id="table-ledger" className ='table' style={{ width:`${table_width}vw`}} >
                        <Streams/>
                    
                        <FlowDomManagement blur={setBlurIndex} Stream={Streams}/>
                      </div>
                    </div>
                  </div>
                </div>

                <div id='totals'>
                  <ScrollSyncPane>
                  <div id="balances_overlay" >
                    {total_balance_dsp}
                  </div>
                  </ScrollSyncPane>

                  <div id='grand_Total_overlay'>
                    <strong><p>Grand Total({general_currency})</p></strong>
                    <div id="grand_Total">
                      <h1 id='grandTotal_text' className='stream'>
                        <NumberFormat
                          value={grandTotal}
                          displayType={'text'}
                          thousandSeparator={true}
                          // prefix={'$'}
                        />
                      </h1>
                    </div>
                  </div>
                </div>
                {/* <SubNav_mobile/> */}
              </span>
            </div>
            </ScrollSync>
          <CreateFlow blur={setBlurIndex}/>
          <EditFlow blur={setBlurIndex}/>
          <RightSide/>
        </div>
        <DeleteFlowModal deleteProp={deleteFlowData} closeModal={closeModal}/>
        <SelectApp />
        <SubNav_mobile/>
        {/* <BusinessProfile/> */}
        <MobileOptions/>
        <MobileProfileSlide/> 
      </section>
    </>
  );
}

  


export default Ledger