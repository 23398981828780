import './options.css' 
import { NavLink } from "react-router-dom";
import right_arrow from '../../icons/mobile_icon/right_arrow.png'
import profile from '../../icons/mobile_icon/profile.png'
import users from '../../icons/mobile_icon/users.png'
import utility from '../../icons/mobile_icon/utility.png'
import display_mode from '../../icons/mobile_icon/display_mode.png'
import create_user from '../../icons/mobile_icon/create_user.png'
import how_to_use from '../../icons/mobile_icon/how_to_use.png'
import logout from '../../icons/mobile_icon/logout.png'
import about from '../../icons/mobile_icon/display_mode.png'

export function MobileOptions() {
    const logOut_Func = () => {
        localStorage.removeItem("refreshToken");
        localStorage.removeItem("accessToken");
    }

    return(
        <>
            <div
                onClick={
                    () => {
                        const elem1 = document.getElementById('optionsOverlay')
                        const elem2 = document.getElementById('optionsList')
                        const elem3 = document.getElementById('mobile_optionsOverlay')
                        const elem4 = document.getElementById('mobile_optionsList')
                        
                        elem1.style.width = '0%' 
                        elem2.style.width = '0%' 
                        elem3.style.width = '0%' 
                        elem4.style.width = '0%' 
                    }
                } 
                id="optionsOverlay"
                className="desktop"
            >
                <div id="optionsList" onClick={(e) => {
                    e.stopPropagation()
                }}>
                    <img id='closeOptions'onClick={() => {
                        const elem1 = document.getElementById('optionsOverlay')
                        const elem2 = document.getElementById('optionsList')
      
                        elem1.style.width = '0%'
                        elem2.style.width = '0%'
                    }} src={right_arrow} alt="" />
                    
                    {/* <NavLink className='NavLink' to="/business_profile"> */}
                        <div id='currentUser' onClick={() => {
                            const elem1 = document.getElementById('optionsOverlay')
                            const elem2 = document.getElementById('optionsList')
                            const elem3 = document.getElementById('optionsOverlay')
                            const elem4 = document.getElementById('optionsList')
          
                            elem1.style.width = '0%'
                            elem2.style.width = '0%'
                        }}>
                            <img src="" alt="" />
                            <div>
                                <p>Godfrey Chibuike Goodluck</p>
                                <small>Admin</small>
                            </div>
                        </div>
                    {/* </NavLink> */}

                    <NavLink className='NavLink' to="/settings/business_profile">
                        <div className="EachOption" onClick={() => {
                            // const elem1 = document.getElementById('optionsOverlay')
                            // const elem2 = document.getElementById('optionsList')
                            // const elem3 = document.getElementById('businessProfileSection')
          
                            // elem1.style.width = '0%'
                            // elem2.style.width = '0%'
                            // elem3.style.width = '100%'
                        }}>
                            <img id='profileOption' className='eachOptionIcon' src={profile} alt="" />
                            Business Profile
                        </div>
                    </NavLink>

                    <NavLink className='NavLink' to="/ledger/NotAvailableYet/RecurringRecord">
                        <div className="EachOption">
                            <img className='eachOptionIcon' src={users} alt="" />
                            Manage Users and Admin
                        </div>
                    </NavLink>

                    <NavLink className='NavLink' to="/ledger/NotAvailableYet/RecurringRecord">
                        <div className="EachOption">    
                            <img id='manageUsers' className='eachOptionIcon' src={utility} alt="" />
                            Utility Settings
                        </div>
                    </NavLink>

                    <NavLink className='NavLink' to="/ledger/NotAvailableYet/RecurringRecord">
                        <div className="EachOption">
                            <img id='displayMode' className='eachOptionIcon' src={display_mode} alt="" />
                            Display Mode  
                        </div>
                    </NavLink>

                    <NavLink className='NavLink' to="/ledger/NotAvailableYet/RecurringRecord">
                        <div className="EachOption">
                            <img id='createAccount' className='eachOptionIcon' src={create_user} alt="" />
                            Create New Account
                        </div>
                    </NavLink>

                    <NavLink className='NavLink' to="/ledger/NotAvailableYet/RecurringRecord">
                        <div className="EachOption">
                            <img id='howToUse' className='eachOptionIcon' src={how_to_use} alt="" />
                            How to use Oga Manager
                        </div>
                    </NavLink>

                    <NavLink className='NavLink' to="/login">
                        <div className="EachOption2 EachOption" onClick={logOut_Func}>
                            <img id='logout' className='eachOptionIcon' src={logout} alt="" />
                            Logout
                        </div>
                    </NavLink>

                    <NavLink className='NavLink' to="/ledger/NotAvailableYet/RecurringRecord">
                        <div id='about' className="EachOption2 EachOption">
                            <img className='eachOptionIcon' src={about} alt="" />
                            About Oga Manager
                        </div>
                    </NavLink>
                </div>
            </div>

            <div
                onClick={
                    () => {
                        const elem1 = document.getElementById('optionsOverlay')
                        const elem2 = document.getElementById('optionsList')
                        const elem3 = document.getElementById('mobile_optionsOverlay')
                        const elem4 = document.getElementById('mobile_optionsList')
                        
                        elem1.style.width = '0%' 
                        elem2.style.width = '0%' 
                        elem3.style.width = '0%' 
                        elem4.style.width = '0%' 
                    }
                } 
                id="mobile_optionsOverlay"
                className="mobile"
            >
                <div id="mobile_optionsList" onClick={(e) => {
                    e.stopPropagation()
                }}>
                    <img id='mobile_closeOptions'onClick={() => {
                        const elem1 = document.getElementById('mobile_optionsOverlay')
                        const elem2 = document.getElementById('mobile_optionsList')
      
                        elem1.style.width = '0%'
                        elem2.style.width = '0%'
                    }} src={right_arrow} alt="" />
                    
                    {/* <NavLink className='NavLink' to="/business_profile"> */}
                        <div id='mobile_currentUser' onClick={() => {
                            const elem1 = document.getElementById('mobile_optionsOverlay')
                            const elem2 = document.getElementById('mobile_optionsList')
                            const elem3 = document.getElementById('mobile_optionsOverlay')
                            const elem4 = document.getElementById('mobile_optionsList')
          
                            elem1.style.width = '0%'
                            elem2.style.width = '0%'
                        }}>
                            <img src="" alt="" />
                            <div>
                                <p>Godfrey Chibuike Goodluck</p>
                                <small>Admin</small>
                            </div>
                        </div>
                    {/* </NavLink> */}

                    {/* <NavLink className='NavLink' to="/business_profile"> */}
                        <div className="mobile_EachOption" onClick={() => {
                            const elem1 = document.getElementById('mobile_optionsOverlay')
                            const elem2 = document.getElementById('mobile_optionsList')
                            const elem3 = document.getElementById('businessProfileSection')
          
                            elem1.style.width = '0%'
                            elem2.style.width = '0%'
                            elem3.style.width = '100%'
                        }}>
                            <img id='mobile_profileOption' className='mobile_eachOptionIcon' src={profile} alt="" />
                            Business Profile
                        </div>
                    {/* </NavLink> */}

                    <NavLink className='NavLink' to="/ledger/NotAvailableYet/RecurringRecord">
                        <div className="mobile_EachOption">
                            <img className='mobile_eachOptionIcon' src={users} alt="" />
                            Manage Users and Admin
                        </div>
                    </NavLink>

                    <NavLink className='NavLink' to="/ledger/NotAvailableYet/RecurringRecord">
                        <div className="mobile_EachOption">    
                            <img id='mobile_manageUsers' className='mobile_eachOptionIcon' src={utility} alt="" />
                            Utility Settings
                        </div>
                    </NavLink>

                    <NavLink className='NavLink' to="/ledger/NotAvailableYet/RecurringRecord">
                        <div className="mobile_EachOption">
                            <img id='mobile_displayMode' className='mobile_eachOptionIcon' src={display_mode} alt="" />
                            Display Mode  
                        </div>
                    </NavLink>

                    <NavLink className='NavLink' to="/ledger/NotAvailableYet/RecurringRecord">
                        <div className="mobile_EachOption">
                            <img id='mobile_createAccount' className='mobile_eachOptionIcon' src={create_user} alt="" />
                            Create New Account
                        </div>
                    </NavLink>

                    <NavLink className='NavLink' to="/ledger/NotAvailableYet/RecurringRecord">
                        <div className="mobile_EachOption">
                            <img id='mobile_howToUse' className='mobile_eachOptionIcon' src={how_to_use} alt="" />
                            How to use Oga Manager
                        </div>
                    </NavLink>

                    <NavLink className='NavLink' to="/login">
                        <div className="mobile_EachOption" onClick={logOut_Func}>
                            <img id='mobile_logout' className='mobile_eachOptionIcon' src={logout} alt="" />
                            Logout
                        </div>
                    </NavLink>

                    <NavLink className='NavLink' to="/ledger/NotAvailableYet/RecurringRecord">
                        <div id='mobile_about' className="mobile_EachOption">
                            <img className='mobile_eachOptionIcon' src={about} alt="" />
                            About Oga Manager
                        </div>
                    </NavLink>
                </div>
            </div>
        </>
    )
}