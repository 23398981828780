// import { useSelector, useDispatch } from 'react-redux'
import { useState, useEffect } from 'react';
import { ledger_subPages_mobileNav } from '../defualt_Layouts/slices/navSliceMobile'
import SubNav_mobile from '../defualt_Layouts/subNav_mobile';
import { MobileProfileSlide } from '../defualt_Layouts/accounts';
import { MobileOptions } from '../defualt_Layouts/options';
import './BusinessProfile.css'
import { SelectApp } from '../defualt_Layouts/selectApp';
import LeftSide from '../defualt_Layouts/leftSide';
import RightSide from '../defualt_Layouts/rightSide';
import SubNav from '../defualt_Layouts/subNav';
import how_to_use from '../../icons/mobile_icon/how_to_use.png'
import right_arrow from '../../icons/mobile_icon/right_arrow.png'

function BusinessProfile(){
    // const dispatch = useDispatch()
    // useEffect(
    //     () => {
    //         dispatch(ledger_subPages_mobileNav())
    //     },[]
    // )

    return(
        <>
            <section className='column'>
                <SubNav visibility="hidden"/>
                <div className='row overlay'>
                    <LeftSide/>
                    
                    <div id='businessProfileSection'>
                        <div id="businessProfile_flag">
                            <div>
                                <img src={right_arrow} alt="" onClick={() => {
                                    window.history.go(-1); 
                                    // return false
                                }}/>
                                {/* <input
                                    action="action"
                                    onclick="window.history.go(-1); return false;"
                                    type="submit"
                                    value="Cancel"
                                /> */}
                                <img src='{how_to_use}' alt="" />
                            </div>
                            <h1>JAEPHICS DESIGN ENTERPRISE</h1>
                        </div>

                        <div id="businessProfile_progress">
                            <div id="businessProfile_progressBar">
                                <div></div>
                            </div>
                            <p>
                                <b>Your Business Profile is 60 percent complete</b>
                                A complete profile makes it easier for customers to find you know what you do.
                            </p>
                            <button>Complete Profile</button>
                        </div>

                        <div id="companyInfo">
                            <h3>Company Info</h3>
                            <p>
                                Manager
                                <b>Clinton Smith Dickson</b>
                            </p>
                            <p>
                                Business Type
                                <b>Manufacturing</b>
                            </p>
                            <p>
                                Business Email
                                <b>siln@gmail.com</b>
                            </p>
                            <p>
                                Office Address
                                <b><address>No 4 clinton street of city road</address></b>
                            </p>
                            <p>
                                Bussines Registration Number
                                <b>RC.123456890</b>
                            </p>
                            <p>
                                Year of Establishment
                                <b>May 2012</b>
                            </p>
                            <p>
                                Number of Employees
                                <b>45</b>
                            </p>
                            <div className='buttonLeft_overlay'>
                                <button>Edit</button>
                            </div>
                        </div>

                        <div id='ManageUsers'>
                            <h4>Admins  and Users</h4>
                            <p>
                                <span>Joel Stone</span>
                                <span>Admin</span>
                            </p>
                            <p>
                                <span>Frank fisherman</span>
                                <span>Admin</span>
                            </p>
                            <div className='buttonLeft_overlay'>
                                <button>Manage</button>
                            </div>
                        </div>

                        <div id="userInfo">
                            My Info
                            <p>
                                Name
                                <b>Uncle Sam</b>
                            </p>
                            <p>
                                status
                                <b>Admin</b>    
                            </p>
                            <p>
                                Phone Number
                                <b>Manufacturing</b>
                            </p>
                            <p>
                                Email
                                <b>siln@gmail.com</b>
                            </p>
                            <div className='buttonLeft_overlay'>
                                <button>Edit</button>
                            </div>
                        </div>
                        {/* <MobileProfileSlide/>
                        <MobileOptions/> */}
                        {/* <SubNav_mobile/> */}
                    </div>
                    <RightSide/>
                </div>
                <SelectApp />
                <SubNav_mobile/>
                <MobileProfileSlide/>
                <MobileOptions/>
            </section>
        </>
    )
}

export default BusinessProfile
    