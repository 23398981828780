import './deleteFlowModal.css'
import closeSquare_dark from '../../../icons/closeSquare_dark.png'

export function DeleteFlowModal(props) {

    return(
        <>
            <div className='desktop' id='deleteFlowModal_Overlay'  onClick={
                props.closeModal
            } >
                <div id="deleteFlowModal" onClick={(e) => {
                    e.stopPropagation()
                }}> 
                    <div id='deleteFlowModal_CloseIcon_overlay'>
                        <img src={closeSquare_dark }alt="" onClick={
                            props.closeModal
                        }/>
                    </div>

                    <p className='mobile'>Are you sure you want to delete</p>
                    <p className='mobile'>this record?</p>
                    <p className='desktop'>Are you sure you want to delete this record?</p>
 
                    <div id='deleteFlowModal_btnOverlay'> 
                        <button onClick={
                            () => {
                                props.deleteProp()
                            }
                        }>Yes</button>
                        <button onClick={
                            props.closeModal
                        }>No</button>
                    </div>
                </div>
            </div>

            <div className='mobile' id='mobile_DeleteFlowModal_Overlay'  onClick={
                props.closeModal    
            } >
                <div id="mobile_DeleteFlowModal" onClick={(e) => {
                    e.stopPropagation()
                }}> 
                    <div id='mobile_DeleteFlowModal_CloseIcon_overlay'>
                        <img src={closeSquare_dark }alt="" onClick={
                            props.closeModal
                        }/>
                    </div>

                    <p >Are you sure you want to delete this record?</p>

                    <div id='mobile_DeleteFlowModal_btnOverlay'>
                        <button onClick={
                            () => {
                                props.deleteProp()
                            }
                        }>Yes</button>
                        <button onClick={
                            props.closeModal
                        }>No</button>
                    </div>
                </div>
            </div>
        </>
    )
}