import React from 'react'
import "./FooterLayout.css"
import SubNav_mobile from './subNav_mobile'
import axios from 'axios';
import toast from 'react-hot-toast';
import {backendURL} from "../../backendURL"

function Manager() {
  if(localStorage.getItem("accessToken")){
    const refreshtoken =  localStorage.getItem("refreshToken");
    const get_tokens  = axios.post(
      `${backendURL}/user/refresh/`,
      {
        refresh: `${refreshtoken}`
      },
      {
        headers: {  
          'Content-Type': 'Application/JSON',
          'Accept': 'Application/JSON',
        },
      }
    )

    get_tokens.then(function (response) {
      localStorage.setItem("refreshToken", response.data.refresh);
      localStorage.setItem("accessToken", response.data.access);
      window.location.assign("/ledger")
    })
    .catch(function (error) {
      if (error.response.status === 400 || error.response.status === 401 || error.response.status === 403 || error.response.status === 500) {
        window.location.assign("/login");
      }
      console.log(error);
    })
  }else{
    window.location.assign("/login")
  }
}

export default Manager