
import React from 'react';

const NetProfit = () => {
    return (
        <div>
            
        </div>
    );
}

export default NetProfit;
