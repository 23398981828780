import { createSlice } from '@reduxjs/toolkit'
import { NavLink } from "react-router-dom";
import "./navSliceMobile.css"
import { UserCompMobile } from '../accounts';

const initialState = {
  value: '',
}

export const navSliceMobile = createSlice({
  name: 'navBarMobile',
  initialState,
  reducers: {
    ledger_mobileNav: (state) => {
        state.value =<> 
            <li><div className='mobile_navs' id='apps' onClick={
                () => { 
                    const elem1 = document.getElementById('selectAppsModal')
                    const elem2 = document.getElementById('myApps')
                    
                    elem1.style.height = '100%' 
                    elem1.style.backgroundColor = 'rgba(0, 0, 0,0.2)' 
                    elem2.style.height = '70vw' 
                    elem2.style.marginTop = '35vw' 
                }
            }>
            </div></li>
            <li><div className='mobile_navs' id='search' onClick={() => {
              const elem1 = document.getElementById('mobileSearch_overlay')
              elem1.style.width = '70%' 
            }}></div></li>
            <li><div className='mobile_navs' id='notifications'></div></li>
            <li><UserCompMobile/></li>
            {/* <li><div className='mobile_navs' id='profile'></div></li> */}
            <li
              onClick={
                () => {
                  const elem1 = document.getElementById('mobile_optionsOverlay')
                  const elem2 = document.getElementById('mobile_optionsList')

                  elem1.style.width = '100%'
                  elem2.style.width = '70%'
                }
              }
            ><div className='mobile_navs' id='options'></div></li>
        </>
    },

    ledger_subPages_mobileNav: (state) => {
        state.value =<> 
            <li><div className='mobile_navs' id='apps'></div></li>
            <li><div className='mobile_navs' id='notifications'></div></li>
            <li><UserCompMobile/></li>
            {/* <li><div className='mobile_navs' id='profile'></div></li> */}
            <li
              onClick={
                () => {
                  const elem1 = document.getElementById('optionsOverlay')
                  const elem2 = document.getElementById('optionsList')

                  elem1.style.width = '100%'
                  elem2.style.width = '70%'
                }
              }
            ><div className='mobile_navs' id='options'></div></li>
        </>
    },

    clear_mobileNav: (state) => {
        state.value =<></>
    },
  },
})

// Action creators are generated for each case reducer function
export const { ledger_mobileNav, ledger_subPages_mobileNav, clear_mobileNav} = navSliceMobile.actions

export default navSliceMobile.reducer