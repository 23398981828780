import React from 'react'
import "./FooterLayout.css"
import SubNav_mobile from './subNav_mobile'

function FooterLayout() {
  return (
    <>
      <footer className='desktop'>@Business 2022</footer>
      {/* <SubNav_mobile/> */}
    </>
  )
}

export default FooterLayout