import './rightSide.css'
import { NavLink } from "react-router-dom";


import React from 'react';

const RightSide = () => {
    return (
        <span id='rightSpan' className='desktop'>
            
        </span>
    );
}

export default RightSide;

