import { useState, useEffect } from 'react';
import RegistrationForm from "./components/Registration_Form"
import EmailSent from "./components/EmailSent"
import './Register.css';
import { clear_nav } from '../defualt_Layouts/slices/navSlice'
import { clear_mobileNav } from '../defualt_Layouts/slices/navSliceMobile'
import { useSelector, useDispatch } from 'react-redux'


function Registration() {
  const Email_sent = () => {
    setRegStatus(<EmailSent />)
  }
  
  const [RegStatus, setRegStatus] = useState(
    <>
      <div id="regTitle">
        <h1>Registration</h1>
      </div>

      <RegistrationForm Email_sent={Email_sent} />
    </>
  );
  const dispatch = useDispatch()
  
  useEffect(
    () => {
      dispatch(clear_nav())
      dispatch(clear_mobileNav())
    },[]
  )

  return (
    <section id='registerSection'>
        <div id="registerOverlay_scroll">
          <div id="registerOverlay">
            {RegStatus}
            {/* <EmailSent/> */}
          </div>
        </div>
    </section>
  );
}

export default Registration 