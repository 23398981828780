import "./Enventory.css"
import LeftSide from '../defualt_Layouts/leftSide';
import RightSide from '../defualt_Layouts/rightSide';
import SubNav_mobile from '../defualt_Layouts/subNav_mobile';
import { MobileProfileSlide } from '../defualt_Layouts/accounts';
import { MobileOptions } from '../defualt_Layouts/options';
import { SelectApp } from '../defualt_Layouts/selectApp';

function Enventory(stream_length) {


  return (
    <section className='column'>
      <div className='row overlay'>
        <LeftSide/>
          <span id='EnventoryOverlay'>
            <div id='oops_Overlay'>
              <p>Oops! There is nothing here yet</p>
              <p>We will notify you as soon as this feature is available</p>
              <div id="oopsIcon"></div>
            </div>
          </span>
        <RightSide/>
      </div>
        <SelectApp />
        <SubNav_mobile/>
        <MobileProfileSlide/>
        <MobileOptions/>
    </section>
  );
}

  


export default Enventory