// http://127.0.0.1:8000/

// export const backendURL = 'https://83ef-105-113-10-71.ngrok.io'  
// export const backendURL = 'http://127.0.0.1:8000'  // DeskTop   
// export const backendURL = 'http://192.168.43.252:8080'   // Infinix
// export const backendURL = 'http://192.168.43.127:8080'   // pop 4
// export const backendURL = 'http://192.168.43.127:8080'   // Julious
// export const backendURL = 'http://192.168.37.127:8080'   // Tamuno
// export const backendURL = 'http://192.168.123.127:8080'   // godstime
// export const backendURL = 'http://192.168.43.252:8080'   // Infinix


// export const backendURL = 'https://theogamanager.com'   // production serverledger
export const backendURL = 'http://theogamanager.com'   // production serverledger