import React, {useEffect, useState} from 'react'
import "./HeaderLayout.css"
import { useSelector, useDispatch } from 'react-redux'
import { NavLink } from "react-router-dom";
import { getRequest }  from "../auths_&_requests/requests" 
import useGlobalState from "../../Store_&_State/GlobalState"
import closeSquare_dark from "../../icons/closeSquare_dark.png"

function HeaderLayout() {
    const navSlice = useSelector((state) => state.navBar.value)
    const navSliceMobile = useSelector((state) => state.navBarMobile.value)
    const {search, setSearch} = useGlobalState('');

    return (
        <header>
            <div id="navbar1" className='desktop'>
                <div id="name">
                    <NavLink to="/login">
                        <h1>Oga Manager</h1>
                    </NavLink>
                </div>
                <ul>
                    {navSlice}
                </ul>
            </div>

            <div id='navbarMobile' className='mobile'>
                <div id="name">
                    <NavLink to="/login">
                        <h1>OGA</h1>
                        <h1>MANAGER</h1>
                    </NavLink>
                </div>
                <ul>
                    {navSliceMobile}
                </ul>
                <div  id="mobileSearch_overlay">
                    <input type="text" value={search} placeholder="Search" onChange={(e)=>{setSearch(e.target.value)}}/>
                    <div id="cancleSearch" onClick={() => {
                        const elem1 = document.getElementById('mobileSearch_overlay')
                        elem1.style.width = '0%' 
                        setSearch('')
                    }}>
                        <img src={closeSquare_dark} alt=""/>
                    </div>
                </div>
            </div>
        </header>
    )
}
export default HeaderLayout
