import axios from 'axios';
import toast from 'react-hot-toast';
import {backendURL} from "../../backendURL"


export const tokenProcess = async (accessToken) => {
  const refreshtoken =  localStorage.getItem("refreshToken");
  console.log(refreshtoken)
  if(refreshtoken){
    const get_tokens  = axios.post(
      `${backendURL}/user/refresh/`,
      {
        refresh: `${refreshtoken}`
      },
      {
        headers: {  
          'Content-Type': 'Application/JSON',
          'Accept': 'Application/JSON',
        },
      }
    )

    get_tokens.then(function (response) {
      // console.log(response.data.refresh)
      localStorage.setItem("refreshToken", response.data.refresh);
      localStorage.setItem("accessToken", response.data.access);
      return accessToken = response.data.access
    })
    // .catch(function (error) {
    //   if (error.response.status === 400 || error.response.status === 401 || error.response.status === 403 || error.response.status === 500) {
    //     window.location.assign("/login");
    //   }
    //   console.log(error);
    // })

    return accessToken
  }
}

export let getRequest = async(url, SelectedAccount, fail, success, doToast, loadingToast, successToast) =>{
  const refreshtoken =  localStorage.getItem("refreshToken");
  // console.log(refreshtoken)
  let accessToken =  localStorage.getItem("accessToken");
  let request=''
  let get =()=>{
    request  = axios.get(
      `${backendURL}/${url}`,
      {
        headers: {  
          'Content-Type': 'Application/JSON',
          'Authorization': `Bearer ${accessToken}`,
        }, 
        params: { 'account_id': `${SelectedAccount.id}` },
      }
    )
    if (doToast == true){
      toast.promise(
        request,
        {
            loading: `${loadingToast}`,
            success:  `${successToast}`,
            error: (data) => `Sorry: ${data.response.data.error}`,
        }
      );
    }
  }
  get()

  request.then(function (response) {
    // console.log(response)
    success(response.data);
  }).catch(function (error) {
    if(error.response.status === 401 || error.response.status === 403 || error.response.status === 500){
      tokenProcess(accessToken)
      get()
      request.then(function (response) {
        success(response.data);
      })
      .catch(function (error) {
        console.log(error);
      })
    }else{
      fail(error);
    }
  })
}

export let postRequest = (url, data, SelectedAccount, fail, success, doToast, loadingToast, successToast) =>{
  let accessToken =  localStorage.getItem("accessToken");
  let request=''
  const post =()=>{
    request  = axios.post(
      `${backendURL}/${url}`,
      data,
      {
        headers: {  
          'Content-Type': 'Application/JSON',
          'Authorization': `Bearer ${accessToken}`,
        },   
        params: { 'account_id': `${SelectedAccount.id}` },
      }
    )

    if (doToast == true){
      toast.promise(
        request,
        {
          loading: `${loadingToast}`,
          success: `${successToast}`,
          error: (data) => `Sorry: ${data.response.data.error}`,
        }
      );
    }
  }
  post()
  request.then(function (response) {
    console.log(response)
    success(response.data);
  }).catch(function (error) {
    if(error.status === 401 || error.status === 403){
      tokenProcess(accessToken)
      post()
      request.then(function (response) {
        success(response.data);
      })
      .catch(function (error) {
        console.log(error);
      })
    }else{
      console.log(error);
      fail(error.response.data.error);
    }
  })
}

export let deleteRequest = (url, SelectedAccount, fail, success, doToast, loadingToast, successToast) =>{
  let accessToken =  localStorage.getItem("accessToken");
  let request=''
  let delete_item =()=>{
    request  = axios.delete(
      `${backendURL}/${url}`,
      {
        headers: {  
          'Content-Type': 'Application/JSON',
          'Authorization': `Bearer ${accessToken}`,
        }, 
        params: { 'account_id': `${SelectedAccount.id}` },
      }
    )
    if (doToast == true){
      toast.promise(
        request,
        {
            loading: `${loadingToast}`,
            success:  `${successToast}`,
            error: (data) => `Sorry: ${data.response.data.error}`,
        }
      );
    }
  }
  delete_item()
  
  request.then(function (response) {
    console.log(response)
    success(response.data);
  }).catch(function (error) {
    if(error.response.status === 401 || error.response.status === 403 || error.response.status === 500){
      tokenProcess(accessToken)
      delete_item()
      request.then(function (response) {
        success(response.data);
      })
      .catch(function (error) {
        console.log(error);
      })
    }else{
      console.log(error);
      fail(error.response.data.error);
    }
  })
}

