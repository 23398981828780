import {backendURL} from "../../../../backendURL"
import toast, { Toaster } from 'react-hot-toast';
import axios from 'axios';

const register_api = async (email, password, first_name, last_name, success, failed) => {
  const register  = axios.post(
    `${backendURL}/user/register/`, 
    {
      "email": email,
      "password": password,
      "first_name": first_name,
      "last_name": last_name,
    }
  )
  
  toast.promise(
    register,
    {
        loading: 'Registering...',
        success: 'Hurray!!',
        error: (data) => `Sorry: ${data.response.data.email !== undefined? data.response.data.email: "somthing went wrong!! pls constact custpmer surport if this proplem persist"}`,
    }
  );

  register.then(function (response) {
    console.log(response);
    // success(response.data);
  })
  .catch(function (error) {
    console.log(error);
    console.log(error.response.data);
    // failed(error.response.data.error);
  })
};

export default register_api;
