import { useState, useEffect } from 'react';
import React from 'react'
import Modal from 'react-modal';
import './createFlow.css'
import useGlobalState from "../../../Store_&_State/GlobalState"
import {getRequest, postRequest} from "../../auths_&_requests/requests"
import NumberFormat from 'react-number-format';
import toast, { Toaster } from 'react-hot-toast';

function EditFlow(prop) {
    const [description, setDescription] = useState("");
    const [selected_Streamm, setSelected_Streamm] = useState("");
    const [credit, setCredit] = useState('');
    const [debit, setDebit] = useState('');
    const [categoryList, setcategoryList] = useState("");
    const [categorySelected, setCategorySelected] = useState("");
    const [newCategory, setNewCategory] = useState("");
    const [selected_Category, setSelected_Category] = useState("");
    const [savedTransaction, setSavedTransaction] = useState('');
    const [selectColor, setSelectColor] = useState("gray");
    const [stream, setStream] = useState([]);
    const [savedTransctionList, setSavedTransctionList] = useState([]);
    
    const {editFlowIsOpen, setEditFlowIsOpen} = useGlobalState([]);
    const {streamList, setStreamList} = useGlobalState([]);
    const {reRender, setReRender} = useGlobalState([0]);
    const {flowId, setFlowId} = useGlobalState([]);
    const {main_Toaster_visibility, setMain_Toaster_visibility} = useGlobalState('');
    const {general_currency, setMain_general_currency} = useGlobalState('');
    const {SelectedAccount, setSelectedAccount} = useGlobalState([]);



    // set stream data............................................................................................
    useEffect(() => {
        setStream(
          streamList.map(function(item){
            return(
                <button className='streamBTNs' id={'stream'+item.id} required onClick={(e)=>{ 
                    e.preventDefault(); 
                    setSelected_Streamm(item.id);
                    const streamBTNs = document.getElementsByClassName('streamBTNs')

                    for( var i =0; i < streamBTNs.length; i++){
                        streamBTNs[i].style.backgroundColor = 'white'
                        streamBTNs[i].style.color = 'var(--color5)'
                    }

                    e.target.style.backgroundColor = 'var(--color5)'
                    e.target.style.color = 'white'
                }}>
                    {item.name}
                </button>
            )
          })
        );
    
    }, [streamList]);


    // modal management......................................................................................................

    function afterOpenModal() {
        setMain_Toaster_visibility('hidden')
        load_categories()
        load_savedTransactions()
        load_edit_data()
    }

    function closeModal(e) {
        setMain_Toaster_visibility('visible')
        e.preventDefault();
        setEditFlowIsOpen(false);
        prop.blur(0)
    }


    // reacord edit.................................................................................................
    const record_edit_Success = async() => {
        setMain_Toaster_visibility('visible')
        setEditFlowIsOpen(false);
        prop.blur(0)
        setReRender(reRender+1)
        setDescription('')
        setCredit('')
        setDebit('')
    };
    const record_edit_fail = () => {

    }

    const edit_Data = async(e)=>{
        e.preventDefault()
        let credit_0 = '0'
        let debit_0 = '0'
        if(credit !== ''){
            credit_0 = credit
        }

        if(debit !== ''){
            debit_0 = debit
        }

        const replace = `[${general_currency}]|,`;
        const re = new RegExp(replace,"g");
        const doToast= true
        const loadingToast= 'Saving Edited Record...'
        const successToast= 'Edited Record Saved'
        const url = `backend_Ledger/cashflow/${flowId}/update/`;
        postRequest(
            url, 
            {
                "description": description,
                "cashStream": selected_Streamm,
                "Credit": credit_0.replace(re, ""),
                "Debit": debit_0.replace(re, ""),
                "category": categorySelected,
            }, 
            SelectedAccount, 
            record_edit_fail, record_edit_Success, 
            doToast, loadingToast, successToast
        )
    };


    // get saved transactions........................................................................................
    const getSavedTransction_success = (data) => {
        setSavedTransctionList(
            data.results.map(function(item){
              return(
                <option value={JSON.stringify(item)}>{item.description}</option> 
              )
            })
        )
    }

    const getSavedTransction_fail = () => {
        
    }
    
    const load_savedTransactions = () => {
        const doToast= true
        const loadingToast= 'Loading Saved Transactions...'
        const successToast= 'Loaded Saved Transactions'
        const url = `backend_Ledger/savedtransaction/`
        if(SelectedAccount.id !== undefined){
            getRequest(url, SelectedAccount, getSavedTransction_fail, getSavedTransction_success, doToast, loadingToast, successToast);
        }
    }

    //apply saved transaaction 
    const applySavedTransaction = (e) => {
        toast('Fields Have Been Updated From Saved Transaction', {
            duration: 4000,
            position: 'top-center',
          
            // Styling
            style: {},
            className: '',
          
            // Custom Icon
            icon: '👌🏼',
          
            // Change colors of success/error/loading icon
            iconTheme: {
              primary: '#000',
              secondary: '#fff',
            },
          
            // Aria
            ariaProps: {
              role: 'status',
              'aria-live': 'polite',
            },
        });

        const selected = JSON.parse(e.target.value)
        if(selected !== ""){
            setCategorySelected(selected.category)
            setCredit(selected.Credit)
            setDebit(selected.Debit)
            setSelected_Streamm(selected.cashStream)
            const streamBTNs = document.getElementsByClassName('streamBTNs')
            for( var i =0; i < streamBTNs.length; i++){
                streamBTNs[i].style.backgroundColor = 'white'
                streamBTNs[i].style.color = 'rgb(142, 143, 196)'
            }
            document.getElementById('stream'+selected.cashStream).style.backgroundColor = 'rgb(142, 143, 196)'
            document.getElementById('stream'+selected.cashStream).style.color = 'white'
            setDescription(selected.description)
        }else{
            setCategorySelected('')
            const categoriesInput = document.getElementsByClassName('categoriesInput')
            for( var i =0; i < categoriesInput.length; i++){
                categoriesInput[i].checked = false
            }
            setCredit('')
            setDebit('')
            setSelected_Streamm('')
            const streamBTNs = document.getElementsByClassName('streamBTNs')
            for( var i =0; i < streamBTNs.length; i++){
                streamBTNs[i].style.backgroundColor = 'white'
                streamBTNs[i].style.color = 'rgb(142, 143, 196)'
            }
            setDescription('')
        }
    }


    // GET category ..................................................................................................
    const getCategories_success = async(data) => {
        // console.log(data)
        setcategoryList(
            data.results[0].map(function(item){
              return(
                <option value={JSON.stringify(item.id)}>{item.name}</option> 
              )
            })
        )

    };

    const getCategories_fail = async(failed) => {
        console.log(failed)
    };

    const load_categories = () => {
        const doToast= true
        const loadingToast= 'Loading Categories...'
        const successToast= 'Loaded Categories'
        const url = `backend_Ledger/category/`
        if(SelectedAccount.id !== undefined){
            getRequest(url, SelectedAccount, getCategories_fail, getCategories_success, doToast, loadingToast, successToast);
        }
    }
    

    // category created................................................................................................
    const create_Category_Success = () => {
        toast('Category Created', {
            duration: 2000,
            position: 'top-center',
          
            // Styling
            style: {},
            className: '',
          
            // Custom Icon
            icon: '👌🏼',
          
            // Change colors of success/error/loading icon
            iconTheme: {
              primary: '#000',
              secondary: '#fff',
            },
          
            // Aria
            ariaProps: {
              role: 'status',
              'aria-live': 'polite',
            },
        });

        load_categories() 
        setNewCategory('')
    }
    const create_Category_Fail = () => {

    }

    const create_Category = async(e)=>{
        e.preventDefault()
        const doToast= true
        const loadingToast= 'Saving Category...'
        const successToast= 'Category Saved'
        const url = 'backend_Ledger/category/';
        postRequest(
            url,
            {"name": newCategory}, 
            SelectedAccount, 
            create_Category_Fail, create_Category_Success, 
            doToast, loadingToast, successToast
        )
    };

    
    // save transactions..............................................................................................
    useEffect(
        () => {
            if(savedTransaction == ''){
                setSelectColor("bolder")
            }else{
                setSelectColor("normal")
            }
        },[savedTransaction]
    )

    const saveTransaction_Success = async(data, page) => {
        load_savedTransactions()
    };
    const saveTransaction_Fail = () => {
        
    }

    const saveTransaction = (e) => {
        e.preventDefault()
        let credit_0 = '0'
        let debit_0 = '0'
        if(credit !== ''){
            credit_0 = credit
        }

        if(debit !== ''){
            debit_0 = debit
        }
        
        const replace = `[${general_currency}]|,`;
        const re = new RegExp(replace,"g");
        const doToast= true
        const loadingToast= 'Saving Transaction...'
        const successToast= 'Transaction Saved'
        const url = 'backend_Ledger/savedtransaction/';
        postRequest(
            url,
            {
                "description": description,
                "cashStream": selected_Streamm,
                "Credit": credit_0.replace(re, ""),
                "Debit": debit_0.replace(re, ""),
                "category": categorySelected,
            },
            SelectedAccount,
            saveTransaction_Fail, saveTransaction_Success, 
            doToast, loadingToast, successToast
        )
    }

    // get get Edit data........................................................................................
    const getEdit_data_success = (values) => {
        setCategorySelected(values.category)
        setCredit(values.Credit)
        setDebit(values.Debit)
        setSelected_Streamm(values.cashStream)
        const streamBTNs = document.getElementsByClassName('streamBTNs')
        for( var i =0; i < streamBTNs.length; i++){
            streamBTNs[i].style.backgroundColor = 'white'
            streamBTNs[i].style.color = 'rgb(142, 143, 196)'
        }
        document.getElementById('stream'+values.cashStream).style.backgroundColor = 'rgb(142, 143, 196)'
        document.getElementById('stream'+values.cashStream).style.color = 'white'
        setDescription(values.description)
    }

    const getEdit_data_fail = () => {
        
    }
    
    const load_edit_data = () => {
        const doToast= true
        const loadingToast= 'Loading Selected Data...'
        const successToast= 'Data Loaded Success'
        const url = `backend_Ledger/cashflow/${flowId}`
        if(SelectedAccount.id !== undefined){
            getRequest(url, SelectedAccount, getEdit_data_fail, getEdit_data_success, doToast, loadingToast, successToast);
        }
    }

  return (
    <div>
      <Modal
        isOpen={editFlowIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        contentLabel="Add new Entry"
        className="Modal"
        overlayClassName="Overlay"
        ariaHideApp={false}
      >
        <Toaster
            position="top-center"
            reverseOrder={false}
            gutter={8}
            containerClassName=""
            containerStyle={{}}
            toastOptions={{
            // Define default options
            className: '',
            duration: 5000,
            style: {
                background: '#7f77e0',
                color: '#fff',
            },

            // Default options for specific types
            success: {
                duration: 3000,
                theme: {
                primary: 'green',
                secondary: 'black',
                },
            },
            }}
        />

        <form id="loginForm" className='column' >

            <div id='record_Header_Overlay' className='row'>
                <h2>Edit Record</h2> 
                <button onClick={closeModal}>X</button>
            </div>
            
            <div id='descriptionOverlay' className="">
            <strong>Description</strong><br/>
                <input className="record_form" autoFocus type="text" id="description" placeholder="e.g. worker salary" onChange={(e)=>{setDescription(e.target.value)}} value={description} /><br/>
            </div>

            <div id='accountOverlay'>
            <strong>Account</strong><br/>
                {stream}
            </div><br/>
              
            <div id='AmountOverlay' className="inputTextOverlay">
            <strong>Amount</strong><br/>
                <div className='row'>
                    <div>
                        Credit<NumberFormat 
                            thousandSeparator={true} 
                            prefix={general_currency}
                            className="record_form" 
                            type="credit"
                            placeholder="0.00" 
                            onChange={(e)=>{setCredit(e.target.value)}} 
                            value={credit}
                        />
                    </div>
                    
                    <div>
                        Debit<NumberFormat 
                            thousandSeparator={true} 
                            prefix={general_currency} 
                            className="record_form" 
                            type="debit" 
                            placeholder="0.00" 
                            onChange={(e)=>{setDebit(e.target.value)}} 
                            value={debit}
                        />
                    </div>
                </div>
            </div>
       
            <div id='categoryOverlay' className='column'>
                <label>
                    <strong>Category</strong> <br/>
                    <select value={categorySelected} onChange={(e)=>{setCategorySelected(e.target.value)}} style={{fontWeight:selectColor, fontSize:'0.7vw'}} >
                        <option value={JSON.stringify('')}>Choose category</option>
                            {categoryList }
                        {/* <option value="grapefruit">coco</option> */}
                    </select>
                </label>

                <div id='addCategoryOverlay'>
                    <strong>Add category</strong><br/>
                    <input className="add_category" type="text" id="category" onChange={(e)=>{setNewCategory(e.target.value)}} value={newCategory} />
                    <button onClick={create_Category}>+</button>
                </div>
            </div>

            <div id='savedTransactionOverlay' className='row'>
                <label>
                    <select value={savedTransaction} onChange={(e)=>{setSavedTransaction(e.target.value); applySavedTransaction(e)}} style={{fontWeight:selectColor, fontSize:'0.7vw'}} >
                        <option value={JSON.stringify('')}>Choose from saved transactions</option>
                            {savedTransctionList}
                        {/* <option value="grapefruit">coco</option> */}
                    </select>
                </label>
                <div className='column'>
                    <button onClick={saveTransaction}></button>
                    Save transaction
                </div>
            </div>
              
            <div id="BTN_overlay" >
                <div>
                    <button id="submit"  value="Done" onClick={closeModal}>cancel</button>
                    <button id="submit" type="submit" value="Done" onClick={edit_Data}>Done</button>
                </div>
            </div>
        </form>
      </Modal>
    </div>
  )
}

export default EditFlow