import {backendURL} from "../../../../backendURL"
import toast from 'react-hot-toast';
import axios from 'axios';


const login_api = async (path, email, password, success, fail) => {
  const login  = axios.post(
    `${backendURL}/user/login/`, 
    {
      "email": email,
      "password": password,
    }
  )
  
  toast.promise(
    login,
    {
        loading: 'Logging In...',
        success: 'Welcome Back',
        error: (data) => `Sorry: ${data.response.data.error}`,
    }
  );

  login.then(function (response) {
    console.log(response);
    success(response.data, path);
  })
  .catch(function (error) {
    console.log(error);
    fail(error.response.data.error);
  })
};
export default login_api