
import React from 'react';

const OwnersEquity = () => {
    return (
        <div>
            
        </div>
    );
}

export default OwnersEquity;
