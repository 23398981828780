import React, {useEffect, useState} from 'react'
import { getRequest }  from "../auths_&_requests/requests" 
import useGlobalState from "../../Store_&_State/GlobalState"
import "./accounts.css"

export function UserComp() {
    const [account_list, setAccount_list] = useState([]);
    const [account_list_html, setAccount_list_html] = useState([]);

    const {SelectedAccount, setSelectedAccount} = useGlobalState([]);
    
    useEffect(() => {
        setSelectedAccount(
            JSON.parse(window.localStorage.getItem('SelectedAccount'))? 
            JSON.parse(window.localStorage.getItem('SelectedAccount')):account_list[0]?account_list[0]:[]
        )
    }, [account_list]);
    
    const fail = async(data) => {
        console.log(data)
    }

    const success = async(data) => {
        // console.log(data.results[0].users_accounts)
        setAccount_list(data.results[0].users_accounts)
    }
    
    useEffect(
        () => {
            const doToast= false
            const loadingToast= 'Fetching Accounts...'
            const successToast= 'Accounts Loaded successfully'
            const url = `user/detail/`
            getRequest(url, {}, fail, success, doToast, loadingToast, successToast);
        },[]
    )

    useEffect(
        () => {
            // console.log(account_list[0])
            let newList = []

            account_list.map(
                function(account, pk, flow_arr){
                    newList.push(
                        <label htmlFor={account.business_name}>
                            <button href="#" onClick={ () => {
                                window.localStorage.setItem('SelectedAccount', JSON.stringify(account))
                                setSelectedAccount(JSON.parse(window.localStorage.getItem('SelectedAccount')))
                            } }>
                                <div>
                                    <img className='dropdown_account_logo' src={account.logo} alt="" />
                                    {account.business_name}
                                </div>
                                <input id={account.business_name} type="radio" />
                            </button>
                        </label>
                    )
                }
            )
            setAccount_list_html(newList) 
        },[account_list]
    )


    return (
        <>
            <div id='user_account_overlay'>
                <div id='user_accounts'>
                    <div>
                        <img className='nav_blocks account_logo' src={SelectedAccount.logo} alt="" />
                        <span id='account_name'>{SelectedAccount.business_name}</span>
                    </div>
                    <span id='dropdown_icon'></span>
                </div>
                <div id="accounts_overlay">     
                    {
                        account_list_html
                    }
                </div>
            </div>
        </>
    )
}

export function MobileProfileSlide() {
    const [account_list, setAccount_list] = useState([]);
    const [account_list_html, setAccount_list_html] = useState([]);

    const {SelectedAccount, setSelectedAccount} = useGlobalState([]);
    
    useEffect(() => {
        setSelectedAccount(
            JSON.parse(window.localStorage.getItem('SelectedAccount'))? 
            JSON.parse(window.localStorage.getItem('SelectedAccount')):account_list[0]?account_list[0]:[]
        )
    }, [account_list]);
    
    const fail = async(data) => {
        console.log(data)
    }

    const success = async(data) => {
        // console.log(data.results[0].users_accounts)
        setAccount_list(data.results[0].users_accounts)
    }
    
    useEffect(
        () => {
            const doToast= false
            const loadingToast= 'Fetching Accounts...'
            const successToast= 'Accounts Loaded successfully'
            const url = `user/detail/`
            getRequest(url, {}, fail, success, doToast, loadingToast, successToast);
        },[]
    )

    useEffect(
        () => {
            // console.log(account_list[0])
            let newList = []

            account_list.map(
                function(account, pk, flow_arr){
                    newList.push(
                        <label htmlFor={account.business_name}>
                            <button href="#" onClick={ () => {
                                window.localStorage.setItem('SelectedAccount', JSON.stringify(account))
                                setSelectedAccount(JSON.parse(window.localStorage.getItem('SelectedAccount')))
                                const elem1 = document.getElementById('mobileProfile_overlay')
                                const elem2 = document.getElementById('mobileProfile')
                                
                                elem2.style.overflowY = 'hidden'
                                elem2.style.maxHeight = '0vw'
                                elem2.style.minHeight = '0vw'
                                elem2.style.paddingTop = '0vw'

                                elem1.style.height = '0%'
                                // elem1.style.width = '0%'
                            } }>
                                <div>
                                    <img src={account.logo} alt="" /> 
                                    {account.business_name}
                                </div>
                                <input id={account.business_name} type="radio" /> 
                            </button>
                        </label>
                    )
                }
            )
            setAccount_list_html(newList) 
        },[account_list]
    )


    return (
        <div className='mobile' id="mobileProfile_overlay"
            onClick={
                () => {
                    const elem1 = document.getElementById('mobileProfile_overlay')
                    const elem2 = document.getElementById('mobileProfile')
                    
                    elem2.style.overflowY = 'hidden'
                    elem2.style.maxHeight = '0vw'
                    elem2.style.minHeight = '0vw'
                    elem2.style.paddingTop = '0vw'

                    elem1.style.height = '0%'
                }
            }
        > 
                <div id="mobileProfile">
                    {  
                        account_list_html
                        
                    }
                    
                    <label >
                        <button href="#" onClick={ () => {
                            
                        } }>
                            <div>
                                <img alt="" /> 
                                Add Account
                            </div>
                        </button>
                    </label>
                </div>
        </div>
    )
}

export function UserCompMobile() {
    const {SelectedAccount, setSelectedAccount} = useGlobalState([]);

    const chooseProfile = () => {
        const elem1 = document.getElementById('mobileProfile_overlay')
        const elem2 = document.getElementById('mobileProfile')

        elem1.style.height = '100%'
        elem1.style.width = '100%'

        elem2.style.overflowY = 'auto'
        elem2.style.maxHeight = '115vw'
        elem2.style.minHeight = '40vw'
        elem2.style.paddingTop = '4vw'
    }

    return (
        <img className='mobile_navs' id='profile' src={SelectedAccount.logo} alt="" onClick={chooseProfile}/>
    )
}
